var localizedDetails = function() {

	var color = "colour";
	var colorCap = "Colour";
	var invalidQuestionMsg = "Questions need to be longer than 5 characters";
	var invalidAnswerMsg = "Answers must be longer than 1 character";
	var phoneNumber = '1-800-300-1336';
	var showCustomerImages = true;
	var showSuccessStories = true;
	
	let localeName = "CANADA";
	let regSM = "&reg;";
	let certainDeliveryParagraph = "It's on time or it's on us. If your event is missed because we didn't ship on time, your order is free.";
	let certainHappinessParagraph = "If you're not 100% satisfied with your order, we'll pay to pick it up and rerun it or refund your money—your choice.";

	return {
		Color: color,
		ColorCap: colorCap,
		InvalidQuestionMsg: invalidQuestionMsg,
		InvalidAnswerMsg: invalidAnswerMsg,
		PhoneNumber: phoneNumber,
		showCustomerImages: showCustomerImages,
		showSuccessStories: showSuccessStories,
		LocaleName: localeName,
		RegSM: regSM,
		CertainDeliveryParagraph: certainDeliveryParagraph,
		CertainHappinessParagraph: certainHappinessParagraph
	}; 
};